import React from "react";
import Layout from "../../components/Layout";

import photoVlad from "../../../static/img/avatar.jpg";

import facebook from "../../img/social/facebook.svg";
import linkedin from "../../img/social/linkedin.svg";
import twitter from "../../img/social/twitter.svg";
import instagram from "../../img/social/instagram.svg";
import altoros from "../../img/altoros-logo.svg";
import epam from "../../img/epam-logo.svg";
import fedex from "../../img/fedex-logo.svg";
import lseg from "../../img/lseg-logo.svg";
import oyper from "../../img/oyper-logo.svg";
import jobshash from "../../img/jobshash-logo.svg";
import jobspush from "../../img/jobspush-logo.svg";
import insightreel from "../../img/insight-reel-logo.svg";
import thomsonReuters from "../../img/tr-logo.svg";
import { SEO } from "../../components/SEO";

export const AboutPageTemplate = () => {
  return (
    <section className="section brandonRegular">
      <div className="container">
        <div className="columns" style={{ paddingBottom: "1em" }}>
          <div className="column is-2">
            <img
              src={photoVlad}
              alt="Ulad Kaminski"
              style={{
                width: "250px",
                boxShadow:
                  "20px -20px 0px 0px rgb(195, 12, 25)"
              }}
            />
          </div>
          <div className="column is-10">
            <p style={{ marginLeft: "1.5em", fontSize: "1.15rem" }}>
              Software developer, advisor, and entrepreneur with over nine years of hands-on experience in microservices
              and cloud systems. Passionate about Agile practices, continuous improvement, and automation. Enthusiastic
              supporter of startup culture and the Fail Fast philosophy. Co-founder of a successful AdTech startup
              running for five years. Speaker at international conferences and a contributor of research and articles.
            </p>
          </div>
        </div>

        <div style={{ marginTop: "3em" }}>
          <div className="vl"></div>
          <div className="columns">
            <div
              className="column is-2 has-text-centered"
              style={{ paddingRight: "0rem" }}
            >
              <p className="is-size-4 chapter brandonPrinted">
                Companies
              </p>
            </div>
            <div
              className="column is-10 columnCompanies"
              style={{ paddingTop: "0.4em", marginLeft: "2em" }}
            >
              <p className="is-size-4">Founded:</p>
              <div
                className="columns"
                style={{ paddingLeft: "1em" }}
              >
                <div className="column is-2">
                  <a
                    title="Oyper"
                    href="https://www.producthunt.com/products/oyper"
                    target="__blank"
                  >
                    <img
                      src={oyper}
                      alt="Oyper"
                      className="logoCompanies"
                    />
                  </a>
                </div>
                <div className="column is-2">
                  <a
                    title="JobsHash"
                    href="https://jobshash.com/"
                    target="__blank"
                  >
                    <img
                      style={{ width: "14em" }}
                      src={jobshash}
                      alt="JobsHash"
                      className="logoCompanies"
                    />
                  </a>
                </div>
                <div className="column is-2">
                  <a
                    title="Jobspush"
                    href="https://jobspush.io/"
                    target="__blank"
                  >
                    <img
                      style={{ paddingLeft: "2em", width: "12em" }}
                      src={jobspush}
                      alt="Jobspush"
                      className="logoCompanies"
                    />
                  </a>
                </div>
                <div className="column is-2">
                  <a
                    title="Insight-Reel"
                    href="https://insight-reel.com/"
                    target="__blank"
                  >
                    <img
                      style={{ paddingLeft: "4em", width: "12em" }}
                      src={insightreel}
                      alt="Insight-reel"
                      className="logoCompanies"
                    />
                  </a>
                </div>
              </div>

              <p className="is-size-4">Partnered with:</p>
              <div
                className="columns"
                style={{ paddingLeft: "1em" }}
              >
                <a
                  className="column"
                  title="Fedex"
                  href="https://www.fedex.com/en-us/home.html"
                  target="__blank"
                >
                  <img
                    src={fedex}
                    alt="Fedex"
                    className="logoCompanies"
                  />
                </a>
                <a
                  className="column"
                  title="Lseg"
                  href="https://www.lseg.com/"
                  target="__blank"
                >
                  <img
                    src={lseg}
                    alt="Lseg"
                    className="logoCompanies"
                  />
                </a>
                <a
                  className="column"
                  title="Epam"
                  href="https://www.epam.com/"
                  target="__blank"
                >
                  <img
                    src={epam}
                    alt="Epam"
                    className="logoCompanies"
                  />
                </a>
                <a
                  className="column"
                  title="Thomson reuters"
                  href="https://www.thomsonreuters.com/en.html"
                  target="__blank"
                >
                  <img
                    src={thomsonReuters}
                    alt="Thomson reuters"
                    className="logoCompanies"
                  />
                </a>
                <a
                  className="column"
                  title="Altoros"
                  href="https://www.altoros.com/"
                  target="__blank"
                >
                  <img
                    src={altoros}
                    alt="Altoros"
                    className="logoCompanies"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="columns marginTopColumns">
            <div
              className="column is-2 has-text-centered"
              style={{ paddingRight: "0rem" }}
            >
              <p className="is-size-4 chapter brandonPrinted">
                Abilities
              </p>
            </div>
            <div
              className="column is-10"
              style={{
                paddingLeft: "1.9em",
                paddingTop: "0.25em",
                marginLeft: "2em"
              }}
            >
              <ul
                style={{
                  listStyle: "disc outside",
                  fontSize: "1.15rem"
                }}
              >
                <li>
                  Pre-sale activities - communication,
                  clarification and fixing scope of project,
                  estimations
                </li>
                <li>
                  Hand-pick a team - technical interview
                  organization
                </li>
                <li>
                  Building burn down charts - leading agile
                  events, development progress monitoring
                </li>
                <li>
                  Architecture development - design patterns,
                  architectural structure of projects
                </li>
                <li>
                  Technical leading - code review, tech
                  advising, development
                </li>
                <li>
                  Collecting feedback from customer - leading
                  demo meeting, solve conflicts
                </li>
                <li>
                  Delivering project - bug fixing,
                  stabilization, acceptance criteria
                  verification
                </li>
              </ul>
            </div>
          </div>

          <div className="columns marginTopColumns">
            <div
              className="column is-2 has-text-centered"
              style={{ paddingRight: "0rem" }}
            >
              <p className="is-size-4 chapter brandonPrinted">
                Get in touch
              </p>
            </div>
            <div
              className="column is-10"
              style={{ paddingTop: "0em", paddingLeft: "1em" }}
            >
              <div
                className="social social-about"
                style={{ paddingTop: "0.3em" }}
              >
                <a
                  title="facebook"
                  href="https://www.facebook.com/uladkaminski"
                  target="__blank"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    className="socialSize"
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com/uladkaminski"
                  target="__blank"
                >
                  <img
                    src={twitter}
                    alt="Twitter"
                    className="socialSize"
                  />
                </a>
                <a
                  title="instagram"
                  href="https://www.instagram.com/uladkaminski/"
                  target="__blank"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    className="socialSize"
                  />
                </a>
                <a
                  title="linkedin"
                  href="https://linkedin.com/in/uladkaminski"
                  target="__blank"
                >
                  <img
                    src={linkedin}
                    alt="Linkedin"
                    className="socialSize"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutPage = () => {
  return (
    <Layout>
      <AboutPageTemplate />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => <SEO pathname={"/about/"} />;
